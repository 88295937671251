<template>
  <div class="partner2">
    <div class="navbar-canvas"></div>

    <HeaderLogo
      title="Нашим партнерам"
      text="С нами легко стать поставщиком на рынке тендерных закупок. С 2020 года нашими партнёрами стали 14 компаний. Их годовой оборот вырос в среднем в 5 раз.<br />
            <br />
            С-МАТЕРИАЛС - новые возможности роста в партнерстве"
    />
    <!-- <b-container>
      <b-row align-v="center" align-h="center" class="partner2__header__content">
        <b-col cols="12" md="8">
          <h1 class="prtner2__header__title">Нашим партнерам</h1>

          <p class="partner2__header__text">
            С нами стать поставщиком на рынке тендерных закупок просто. С 2018
            года нашими партнерами стали 100 компаний, годовой оборот, которых
            вырос в среднем в 5 раз за 2020 год.<br />
            <br />
            С-МАТЕРИАЛС - новые возможности роста в партнерстве
          </p>
        </b-col>
      </b-row>
    </b-container> -->

    <LineInfo
      title="Партнеры и услуги"
      description="Узнайте подробнее о процессе найма и всех открытых вакансиях."
    />

    <div class="partner2__cards">
      <b-container>
        <b-row align-h="center">
          <b-col cols="12" md="8">
            <div
              v-for="(partner, index) in partners"
              :key="index"
              class="partner2__card"
            >
              <h2 class="partner2__card_title">{{ partner.title }}</h2>
              <p class="partner2__card_text" v-html="partner.text"></p>

              <div v-for="(item, index) in partner.links" :key="index">
                <a
                  class="partner2__card_link"
                  :href="item.link"
                  target="_blank"
                  >{{ item.title }}</a
                >
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <ContactForm />

    <!-- <News /> -->
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import News from "@/components/News.vue";
import LineInfo from "@/components/LineInfo.vue";
import HeaderLogo from "@/components/HeaderLogo.vue";

export default {
  name: "PartnerList",
  components: {
    ContactForm,
    News,
    LineInfo,
    HeaderLogo,
  },
  data() {
    return {
      partners: [
        {
          title: "D-Link",
          text: `
          Ведущий мировой производитель сетевого оборудования корпоративного уровня и профессионального телекоммуникационного оборудования на основе технологий Metro Ethernet, PON, xDSL, Wi-Fi. Также D-Link занимает лидирующие позиции в производстве сетевого оборудования потребительского класса и устройств для «умного дома».
          `,
          links: [
            {
              title: "dlink.ru",
              link: "https://www.dlink.ru",
            },
            "",
          ],
        },
        {
          title: 'ООО "НПП "Бевард"',
          text: `
          Российский разработчик и производитель оборудования для систем видеонаблюдения, СКУД, IP домофонии.<br />
          г. Красноярск, ул.Молокова 16, оф. 355
          `,
          links: [
            {
              title: "beward.ru",
              link: "https://www.beward.ru/",
            },
            "",
          ],
        },
        {
          title: 'ООО "РИО КАР"',
          text: `
          Аренда авто бизнес-класса без водителя<br />
          Красноярский край, г. Красноярск, ул. Алексеева, 49, ДЦ "Вертикали" 14 этаж
          `,
          links: [
            {
              title: "риокар.рус",
              link: "https://xn--80apgpie.xn--p1acf/krasnoyarsk",
            },
          ],
        },
        {
          title: 'ООО "СибТехноСтрой"',
          text: `
          Системы связи - СКС, ЛВС, ВОЛС. Видеонаблюдение, Охранно-пожарная сигнализация (ОПС), Системы автоматизации (АСТУП), Системы контроля доступа (СКУД).<br />
          г. Абакан, ул. Кравченко 11Ф Строение 1
          `,
          links: [
            {
              title: "sts19.ru",
              link: "http://sts19.ru",
            },
            "",
          ],
        },
        {
          title: 'ООО "НОВАТЕХ"',
          text: `
          Оптовые поставки теплоизоляции и огнезащиты инженерных систем (трубопроводы оборудование).<br />
          Красняорский край, г.Красноярск, ул. Дудинская, д.3, стр.5
          `,
          links: [
            {
              title: "novateh24@mail.ru",
              link: "mailto:novateh24@mail.ru",
            },
          ],
        },
        {
          title: "ИП РЕШЕТНИКОВ МАКСИМ ВЛАДИМИРОВИЧ",
          text: `
          Продажа и монтаж окон, витражей, дверей из ПВХ и алюминиевых профилей, навесных вентилируемых фасадов.<br />
          Красняорский край, г.Красноярск, Алексеева 49 офис 719
          `,
          links: [
            {
              title: "balkon124.ru",
              link: "https://balkon124.ru",
            },
          ],
        },
        {
          title: 'ООО "Инновационные строительные технологии" (ООО «ИСТ»)',
          text: `
          Производство: нестандартного оборудования; металлоконструкций; деревянных конструкций; полимерной продукции; металлообработка; огнеупорная футеровка; антикоррозийная и огнезащита; вентиляция; бестраншейная прокладка труб; инженерные сети; экспертиза и неразрушающий, контроль оборудования.<br />
          Юридический адрес: <br />
          Красноярский край, г. Красноярск, ул. Новосибирская д. 60 пом. 8. <br />
          Фактический адрес: <br />
          Красноярский край, г. Красноярск, Транспортный проезд д.1 
          `,
          links: [
            {
              title: "24ist.ru",
              link: "http://24ist.ru",
            },
          ],
        },
        {
          title: 'ООО "Экосистема"',
          text: `
          Продажа, монтаж, обслуживание климатического оборудования Химическая промывка теплообменного оборудования, котельных, продажа химсоставов для промывки Продажа, монтаж и обслуживание жироуловителей.<br />
          Ломоносова, 70 стр. 37 (офис, склад)<br />
          Шоу-рум кондиционеров, компактных приточных установок и установок с рекуперацией тепла на Октябрьской, 8
          `,
          links: [
            {
              title: "proclimate24",
              link: "http://proclimate24.ru",
            },
            {
              title: "ecosystem.ooo",
              link: "https://ecosystem.ooo",
            },
          ],
        },
        {
          title: 'ООО "КЗСК"',
          text: `
          Изготовление и монтаж металлоконструкций любой сложности, Резка металла, Гибка и вальцовка металла, Нестандартные металлоконструкции, Водонапорные башни, Резервуары горизонтальные стальные (РГС) и емкости подземные (ЕП), Антенно-мачтовые сооружения<br />
          Красноярский край, г. Красноярск, улица Академика Павлова, дом 1 строение 61
          `,
          links: [
            {
              title: "24zsk.ru",
              link: "https://24zsk.ru",
            },
          ],
        },
        {
          title: 'ООО ГК "Проф-Система"',
          text: `
          Группа компаний "Проф-система" производит полный ассортимент мебели для дошкольных учреждений, частных детских садов, игровых комнат, для школ, средних и высших учебных заведений, медицинских учреждений различного профиля, гостиниц, библиотек.<br />
          660050, г. Красноярск, ул. Новая, дом 13, оф 2-08
          `,
          links: [
            {
              title: "prof-sistema.ru",
              link: "https://www.prof-sistema.ru",
            },
          ],
        },
        {
          title: 'ООО "АРТСТРОЙ"',
          text: `
          Комплексный ремонт жилой и коммерческой недвижимости, Дизайнерский ремонт, Эксклюзивный ремонт, Ремонт бизнес-класса<br />
          Юридический адрес : 660010, Красноярский край, г. Красноярск, пр-т им. Газеты "Красноярский Рабочий", д. 150, стр. 11, офис 2-17
          `,
          links: [
            {
              title: "artstroysk.ru",
              link: "https://artstroysk.ru",
            },
          ],
        },
        {
          title: 'ООО "ПСК ОРТА"',
          text: `
          Проектирование и строительство, Инженерные изыскания.<br />
          Россия, Красноярский край, г. Красноярск, ул. Полтавская д.38, оф. 308.
          `,
          links: [
            {
              title: "orta24.ru",
              link: "http://orta24.ru",
            },
            {
              title: "tev01@orta24.ru",
              link: "mailto:tev01@orta24.ru",
            },
          ],
        },
        {
          title: "ИП ПОДКОВАЛЬНИКОВ АНТОН АНДРЕЕВИЧ",
          text: `
          Аллигатор. Бизнес-клуб. Красноярск, обучение публичным выступлениям, лекции по мировоззрениям и личному бренду, проведение мероприятий
          `,
          links: [
            {
              title: "instagram",
              link: "https://www.instagram.com/podkovalnikov",
            },
          ],
        },
        {
          title: "ИП Купряков Федор Иванович",
          text: `
          Бизнес Сообщество БКTeams, Специализация в области продаж, клиентоориентированности, эффективных систем управления, корпоративной культуры и сплочения команды.
          `,
          links: [
            {
              title: "ВК",
              link: "https://vk.com/seeds_and_joy",
            },
            {
              title: "instagram",
              link: "https://www.instagram.com/fedorjoy",
            },
            {
              title: "bkteams.ru",
              link: "https://bkteams.ru",
            },
          ],
        },
        {
          title: 'ООО "ВИДЕОСЕРВИС"',
          text: `
          Установку систем видеонаблюдения, Монтаж систем контроля доступа,  Инсталляцию систем оповещения, Гарантийное и не гарантийное обслуживание систем безопасности, Диагностика системы безопасности и ее последующая модернизация.<br />
          ул. 60 лет Октября, 115, Красноярск (оф. 1)
          `,
          links: [
            {
              title: "videoservis-krsk24.turbo.site",
              link: "https://videoservis-krsk24.turbo.site/#legal",
            },
          ],
        },
        {
          title: "Idoors",
          text: `
          Межкомнатные двери, Раздвижные системы, Панели, Двери под ключ.<br />
          Красноярск, Октябрьская, 8
          `,
          links: [
            {
              title: "instagram",
              link: "https://www.instagram.com/idoor24/",
            },
            {
              title: "profildoors.ru",
              link: "https://www.profildoors.ru/catalog/",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.partner2__header__content {
  min-height: 80vh;

  padding-top: 30vh;
  padding-bottom: 30vh;
}
.prtner2__header__title {
  color: $white;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 15px;
  text-align: center;
}
.partner2__header__text {
  color: $white;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
  text-align: center;
}

//swiper
.partner2__cards {
  padding-bottom: 100px;
}
.partner2__card {
  background-color: $gray;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 15px;
  border: 1px solid rgba($color: $accent, $alpha: 0.5);
  transition: all 300ms;
}
.partner2__card:hover {
  border: 1px solid rgba($color: $accent, $alpha: 0.7);
  transition: all 300ms;
  box-shadow: 0 0px 3.1px -15px rgba($accent, 0.06),
    0 0px 10.3px -15px rgba($accent, 0.08), 0 0px 46px -15px rgba($accent, 0.1);
}
.partner2__card_title {
  text-align: center;
  font-size: 18px;
}
.partner2__card_text {
  text-align: start;
  font-size: 16px;
  color: $gray__medium_light;
}
.partner2__card_link {
  color: $accent;
}
.partner2__card_link:hover {
  color: $gray__ultra_light;
}
</style>
