<template>
  <div class="header">
    <div v-if="showMap" class="geography_company__backmap__wrapper">
      <img
        class="geography_company__backmap__img"
        :src="require('@/assets/svg/map-default.svg')"
      />
    </div>

    <b-container>
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="7" class="header-text-wrapper">
          <b-row align-v="center" align-h="start">
            <b-col cols="12" md="11">
              <h1 class="header_title-page header_title">{{ title }}</h1>

              <p class="header_text" v-html="text"></p>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" md="5">
          <b-row align-v="center" align-h="center">
            <div class="company_header_logo__wrapper">
              <img
                class="header_logo"
                :src="require('@/assets/svg/logo-smaterials.svg')"
                alt=""
              />
            </div>
          </b-row>

          <b-row align-h="center" v-if="showLogoTitle">
            <h2 class="header_logo__title">С-МАТЕРИАЛС</h2>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "HeaderLogo",
  props: {
    showMap: Boolean,
    showLogoTitle: Boolean,
    title: String,
    text: String,
    logo: String,
  },
};
</script>

<style lang="scss">
.company_header_logo__wrapper {
  height: 200px;
  width: 200px;
}
.header {
  position: relative;
  padding-top: 25vh;
  padding-bottom: 25vh;
}
.header_logo {
  width: 100%;
}

.geography_company__backmap__wrapper {
  position: absolute;
  z-index: 2;
  height: 80vh;
  width: 100vw;

  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  filter: opacity(30%) saturate(0%);
}
.geography_company__backmap__img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
}
</style>
